// @ts-nocheck
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./HeaderStyles.css";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo/logo.svg";
import Row from "../../../node_modules/react-bootstrap/esm/Row";
import Col from "../../../node_modules/react-bootstrap/esm/Col";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import CookStoveProject from "../../assets/photos/cookstove-project.jpeg";
import LedProject from "../../assets/photos/led-project.avif";
import FoodDigesterProject from "../../assets/photos/food-digester-project.jpeg";
import SolarProject from "../../assets/photos/solar-project.jpeg";
import CarbonOffset from "../../assets/photos/Carbon-Offset.jpeg";
import CarbonFootprinting from "../../assets/photos/carbon-footprinting.jpeg";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [hoveredImage, setHoveredImage] = useState(Logo); // Default logo

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "unset";
  }, [isOpen]);

  useEffect(() => {
    const dropdownMenu = document.querySelector(".dropdown-menu");
    if (dropdownMenu) {
      dropdownMenu.style.animation = isClosing ? "slide-out 500ms" : "";
    }
  }, [isClosing]);

  const handleToggle = () => {
    if (isOpen) {
      setIsClosing(true);
      setTimeout(() => {
        setIsOpen(false);
        setIsClosing(false);
      }, 500); // Match this duration to the animation duration
    } else {
      setIsOpen(true);
    }
  };

  const handleHover = (newImage) => {
    setHoveredImage(newImage);
  };

  const handleMouseLeave = () => {
    setHoveredImage(Logo);
  };

  return (
    <>
      {["md"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className="header"
        >
          <Container fluid>
            <Link to="/">
              <img
                className="navbar-logo"
                src={Logo}
                alt="logo"
              />
            </Link>
            <Navbar.Brand href="/">Alphabet Greens</Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Alphabet Greens
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="nav-body  flex-grow-1 pe-3">
                  <Link to="/">Home</Link>
                  <Link to="/AboutUs">About Us</Link>
                  <Link to="/Impact">Our Impact</Link>

                  <NavDropdown
                    title="What We Do"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                    show={isOpen}
                    onToggle={setIsOpen}
                  >
                    <FontAwesomeIcon
                      className="header-close-btn"
                      icon={icon({ name: "x" })}
                      onClick={handleToggle}
                    />
                    <Row className="nav-links">
                      <Col
                        className="climate-investments"
                        md={4}
                      >
                        <div>
                          <h5>Climate Investments</h5>
                          <b>Community Based Projects</b>
                        </div>
                        <ul>
                          <NavDropdown.Item
                            onMouseEnter={() => handleHover(CookStoveProject)}
                            onMouseLeave={handleMouseLeave}
                          >
                            <Link to="/CookstoveProject">
                              <li>The CookStove Project</li>
                            </Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onMouseEnter={() => handleHover(LedProject)}
                            onMouseLeave={handleMouseLeave}
                          >
                            <li>The LED Project</li>
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onMouseEnter={() =>
                              handleHover(FoodDigesterProject)
                            }
                            onMouseLeave={handleMouseLeave}
                          >
                            <li>The Food Digester Project</li>
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onMouseEnter={() => handleHover(SolarProject)}
                            onMouseLeave={handleMouseLeave}
                          >
                            <li>The Solar Project</li>
                          </NavDropdown.Item>
                        </ul>
                      </Col>
                      <Col
                        className="offset-portfolio"
                        md={4}
                      >
                        <div>
                          <h5>Offset Portfolio Management Services</h5>
                        </div>
                        <ul>
                          <NavDropdown.Item
                            onMouseEnter={() => handleHover(CarbonOffset)}
                            onMouseLeave={handleMouseLeave}
                          >
                            <li>Carbon Offset</li>
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onMouseEnter={() => handleHover(CarbonFootprinting)}
                            onMouseLeave={handleMouseLeave}
                          >
                            <li>Carbon Footprinting</li>
                          </NavDropdown.Item>
                        </ul>
                      </Col>
                      <Col
                        className="trading-credits"
                        md={4}
                      >
                        <h5>Trading Carbon Credits</h5>
                        <ul>
                          <NavDropdown.Item
                            onMouseEnter={() => handleHover(CarbonOffset)}
                            onMouseLeave={handleMouseLeave}
                          >
                            <li>Selling Credits</li>
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onMouseEnter={() => handleHover(CarbonOffset)}
                            onMouseLeave={handleMouseLeave}
                          >
                            <li>Buying Credits</li>
                          </NavDropdown.Item>
                        </ul>
                      </Col>
                    </Row>
                    <Row
                      className="header-banner"
                      style={{ backgroundImage: `url(${hoveredImage})` }}
                    >
                      {/* <img
                        className="header-banner-img"
                        src={hoveredImage}
                        alt="Hovered Item"
                      /> */}
                    </Row>
                  </NavDropdown>
                  <Link to="/Contact">Contact Us</Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}
