import React from "react";
import "./Red.css";
import { Row, Col } from "react-bootstrap";
import Banner1 from "../../assets/photos/cookstove-project-bg.jpeg";
import Banner2 from "../../assets/photos/cookstove-project1-bg.jpeg";

export default function Red() {
  return (
    <Row className="red">
      <Col>
        <img
          src={Banner1}
          alt=""
        />
        <img
          className="mt-4"
          src={Banner2}
          alt=""
        />
      </Col>
      <Col>
        <h5>Our Program</h5>
        <p>
          We work closely with the communities we serve to build long-term
          relationships based on trust, encouraging a strong feedback loop and
          allowing us to understand the women’s needs and challenges better.
          This enables constant improvement in our project design and
          implementation, which, coupled with a heavy investment in technology
          and human capital, ensures projects are executed to the highest
          standards.
        </p>
        <p>
          Our innovative stove champion program employs local community members
          to support the roll-out and adoption of the stoves. A local connection
          creates multiple benefits, including ongoing community engagement to
          ensure uptake and support, verification of usage, and long-term local
          employment opportunities.
        </p>
        <p>
          We utilize a direct deployment approach through our field teams and a
          select group of trusted partners (NGOs and local companies) providing
          scalable impact that combines our expertise with local networks.
        </p>
        <p>
          A cloud database and app data management system enables real-time
          tracking of information from field teams, including GPS location,
          scanning of stove serial number, and photos.
        </p>
      </Col>
    </Row>
  );
}
