// @ts-nocheck
import React from "react";

import { Col, Row } from "react-bootstrap";
import "./VerificationStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import Carbon from "../../assets/logo/gsLogo.png";
import { Link } from "react-router-dom";
import Summary from "../../assets/docs/summary.pdf";
import Grievance from "../../assets/docs/Grievance.pdf";
import Announcement from "../../assets/docs/Announcement.pdf";

export default function Verification(props) {
  return (
    <div className="Verification">
      <Row className="Verification-container">
        <Col
          className="Verification-header"
          lg="6"
          md="12"
        >
          <h2>
            All our projects are certified by the highest available voluntary
            carbon standards
          </h2>
        </Col>
        <Col
          className="Verification-cards"
          lg="6"
          md="12"
        >
          <Row className="mb-5">
            <Col
              lg="6"
              md="12"
            >
              <img
                src={Carbon}
                alt=""
              />
              <hr />
              <p>
                The Programme of Activity (PoA) is registered under Gold
                Standard Mechanism with GS id:12494 and its first Real Case VPA
                with GS id: 12495.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <FontAwesomeIcon icon={icon({ name: "file-pdf" })} />
              <Link
                to={Grievance}
                rel="noopener noreferrer"
                target="_blank"
                className="verificationDownloads"
              >
                Grievance Mechanism
              </Link>
            </Col>
            <Col>
              <FontAwesomeIcon icon={icon({ name: "file-pdf" })} />
              <Link
                to={Summary}
                rel="noopener noreferrer"
                target="_blank"
                className="verificationDownloads"
              >
                Non Technical Summary of Project
              </Link>
            </Col>
            <Col>
              <FontAwesomeIcon icon={icon({ name: "file-pdf" })} />
              <Link
                to={Announcement}
                rel="noopener noreferrer"
                target="_blank"
                className="verificationDownloads"
              >
                Announcement of Local Stakeholder Consultation
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
