// @ts-nocheck
import React from "react";
// @ts-ignore
import Carousel from "react-bootstrap/Carousel";

// @ts-ignore
import Button from "react-bootstrap/Button";

import "./BannerStyles.css";
// @ts-ignore
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Banner({ carouselData }) {
  return (
    <Carousel
      slide={false}
      fade={true}
      controls={false}
      className="BannerCarousel"
    >
      {carouselData.map((item, index) => (
        <Carousel.Item
          key={index}
          interval={item.interval}
        >
          <div className="overlay"></div>
          <video
            src={item.videoSrc}
            autoPlay
            loop
            muted
            className="BannerImg"
          />
          <Carousel.Caption>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            <Button
              className="BannerBtn"
              variant="outline-light"
            >
              <b>{item.buttonText}</b>
              {"  "}
              <FontAwesomeIcon
                className="BannerBtnIcon"
                icon={item.buttonIcon}
              />
            </Button>
            <div className="mouse-down center">
              <div className="bullet"></div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
