import React from "react";

import { Col, Row } from "react-bootstrap";
import "./MechanismStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import Carbon from "../../assets/photos/carbon.svg";
import CarbonCredits from "../../assets/photos/carbon-credit.svg";
import CarbonCreditsProceeds from "../../assets/photos/carbon-credit-proceeds.svg";
import CarbonNeutral from "../../assets/photos/net-zero.svg";

export default function Mechanism() {
  return (
    <div className="Mechanism">
      <Row className="Mechanism-container">
        <Col
          className="Mechanism-header"
          lg="6"
          md="12"
        >
          <h2>Our Carbon Offset Mechanism in Action</h2>
        </Col>
        <Col
          className="Mechanism-cards"
          lg="6"
          md="12"
        >
          <Row className="mb-5">
            <Col
              lg="6"
              md="12"
            >
              <img
                src={Carbon}
                alt=""
              />
              <hr />
              <h3> Carbon offset PROJECTS</h3>
              <p>
                Our own carbon offset projects leads to removal of CO
                <sub>2</sub> from the atmosphere and thereby generate carbon
                credits.
              </p>
            </Col>
            <Col
              lg="6"
              md="12"
            >
              <img
                src={CarbonCredits}
                alt=""
              />
              <hr />
              <h3>Carbon CREDITS</h3>
              <p>
                Each of these credits correspond to one tonne of CO<sub>2</sub>{" "}
                and are validated by approved VVBs and are certified Gold
                Standard.
              </p>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col
              lg="6"
              md="12"
            >
              <img
                src={CarbonCreditsProceeds}
                alt=""
              />
              <hr />
              <h3>Carbon credit PROCEEDS</h3>
              <p>
                The proceeds from the certificates will flow back into our
                carbon-offset projects, thereby enabling them to grow further.
              </p>
            </Col>
            <Col
              lg="6"
              md="12"
            >
              <img
                src={CarbonNeutral}
                alt=""
              />
              <hr />
              <h3>Carbon NEUTRALITY</h3>
              <p>
                Products, processes or entire companies can be made carbon
                neutral through off setting the emissions by purchase of carbon
                credits.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
