import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import "./Green.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function Green() {
  return (
    <Row className="green">
      <Col>
        <h5>How big is the problem we’re solving? </h5>
        <p>
          It’s huge. Nearly 2.4 billion people cook their meals using
          inefficient three-stone fires, typically three-stone fires that burn
          solid biomass fuels, like wood & charcoal. They’re inefficient,
          unhealthy, dangerous, and a major contributor to carbon emissions
          worldwide.
        </p>
        <Button
          className="d-block mt-5 m-auto"
          variant="outline-dark"
        >
          Watch Video
          <span>
            <FontAwesomeIcon icon={icon({ name: "play" })} />
          </span>
        </Button>
      </Col>
      <Col>
        <h5>How we’re solving it</h5>
        <p>
          We developed an efficient, clean cookstove that can be readily
          deployed to the people who need it – with lasting benefits for their
          families, communities, and the planet. And we’re deploying them at
          scale.{" "}
        </p>
        <p className="pt-2">
          In the growing movement to promote the adoption of clean cooking, we
          are leading the world.
        </p>
        <h4>
          Already, we have deployed over 30 thousand clean cookstoves across
          Rajasthan, Chattisgarh and Odisha. The impact on the planet and the
          lives of millions of people in need – the rural poor – is immediate
          and significant.
        </h4>
      </Col>
    </Row>
  );
}
