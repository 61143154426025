import Banner from "../components/Common/Banner";
import React from "react";
import Banner1 from "../assets/videos/forest.mp4";

import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Col, Row } from "react-bootstrap";

const carouselData = [
  {
    videoSrc: Banner1,
    title: "We're on a quest",
    description:
      "Empowering women and improving the lives of the rural poor while combating climate change.",
    buttonText: "Learn More",
    buttonIcon: icon({ name: "arrow-right" }),
    interval: 3000,
  },
];

export default function AboutUsScreen() {
  return (
    <>
      <Banner carouselData={carouselData}></Banner>

      <Row className="green-bg">
        <Col></Col>
        <Col></Col>
      </Row>
    </>
  );
}
