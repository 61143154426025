// @ts-nocheck
import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Logo from "../../assets/logo/white.svg";
import "./FooterStyles.css";
// @ts-ignore
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// @ts-ignore
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Link } from "../../../node_modules/react-router-dom/dist/index";

export default function Footer(params) {
  return (
    <Container fluid>
      <Col className="footer">
        <Row>
          <Col>
            <h3 className="footer-header">
              Solving the climate emergency starts today
            </h3>
          </Col>
          <Col>
            <Row className="ButtonRow justify-content-end align-items-end mt-2">
              <Col>
                <Button className="footerBtn">Contact Sales</Button>
              </Col>
              <Col>
                <Button
                  className="footerBtnWhite"
                  variant="outline-light"
                >
                  Explore Projects
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className="footer-hr mb-4" />
        <Row className="footer-sitemap">
          <Col>
            <img
              src={Logo}
              width="64px"
              alt="alphabet greens logo"
            ></img>
          </Col>
          <Col>
            <h5>Home</h5>
            <ul>
              <li>Impact</li>
              <li>Approach</li>
              <li>Terms & Conditions</li>
            </ul>
          </Col>
          <Col>
            <h5>About Us</h5>
            <ul>
              <li>News & Blogs</li>
              <li>Manifesto</li>
              <li>FAQ</li>
            </ul>
          </Col>
          <Col>
            <h5>Contact Us</h5>
            <ul>
              <li></li>
              <li>Partner Policies</li>
              <li>Careers</li>
              <li>Stakeholder Feedback</li>
            </ul>
          </Col>
          <Col>
            <h5>Socials</h5>
            <Row className="footer-socials ">
              <Col>
                <Link href=" ">
                  <FontAwesomeIcon
                    className="BannerBtnIcon"
                    icon={icon({ name: "facebook", style: "brands" })}
                  />
                </Link>
              </Col>
              <Col>
                <Link href=" ">
                  <FontAwesomeIcon
                    className="BannerBtnIcon"
                    icon={icon({ name: "instagram", style: "brands" })}
                  />
                </Link>
              </Col>
              <Col>
                <Link href=" ">
                  <FontAwesomeIcon
                    className="BannerBtnIcon"
                    icon={icon({ name: "twitter", style: "brands" })}
                  />
                </Link>
              </Col>
              <Col>
                <Link href=" ">
                  <FontAwesomeIcon
                    className="BannerBtnIcon"
                    icon={icon({ name: "linkedin", style: "brands" })}
                  />
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className="footer-hr mb-4" />
        <Row className="footer-bottom justify-content-between">
          <Col lg={4}>© Copyright 2023</Col>
          <Col lg={5}>
            <Row className="justify-content-end">
              <Col
                lg={3}
                xs={3}
              >
                <Link>Privacy policy</Link>
              </Col>
              <Col
                lg={3}
                xs={3}
              >
                <Link>Terms of service</Link>
              </Col>
              <Col
                lg={3}
                xs={3}
              >
                <Link>Legal Notice</Link>
              </Col>
              <Col
                lg={3}
                xs={3}
              >
                <Link>Cookies policy</Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Container>
  );
}
