import React from "react";

import Banner from "../components/Common/Banner";

import Banner1 from "../assets/videos/Gujarat_Indian_Village_Cooking_Drumstick_Recipe_Vi-vmake.mp4";

import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

import Green from "components/CookstoveProject/Green";
import Red from "components/CookstoveProject/Red";

const carouselData = [
  {
    videoSrc: Banner1,
    title: "The Cookstove Project",
    description: "Clean. Efficient. Durable.",
    buttonText: "Learn More",
    buttonIcon: icon({ name: "arrow-right" }),
    interval: 3000,
  },
];

export default function CookstoveProjectScreen() {
  return (
    <>
      <Banner carouselData={carouselData}></Banner>
      <Green></Green>
      <Red></Red>
    </>
  );
}