import React from "react";
// import { Helmet } from "react-helmet-async";
import Banner from "../components/Common/Banner.js";
import Mechanism from "../components/Home/Mechanism.js";
import Verification from "../components/Home/Verification.js";

import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

import Banner1 from "../assets/videos/mountains.mp4";
import Banner2 from "../assets/videos/shore.mp4";
import Banner3 from "../assets/videos/sunset.mp4";

const carouselData = [
  {
    videoSrc: Banner1,
    title: "Carbon Neutral",
    description: "Leading India's stride to reduce Emissions",
    buttonText: "Learn More",
    buttonIcon: icon({ name: "arrow-right" }),
    interval: 3000,
  },
  {
    videoSrc: Banner2,
    title: "Our Planet",
    description: "Caring and Giving Back to Mother Nature",
    buttonText: "Learn More",
    buttonIcon: icon({ name: "arrow-right" }),
    interval: 2000,
  },
  {
    videoSrc: Banner3,
    title: "Climate Protection",
    description: "Eco-friendly Energy Solutions",
    buttonText: "Learn More",
    buttonIcon: icon({ name: "arrow-right" }),
    interval: 2000,
  },
];

export default function HomeScreen() {
  return (
    <>
      {/* <Helmet>
        <title>Alphabet Greens</title>
      </Helmet> */}
      <Banner carouselData={carouselData}></Banner>
      {/* 
          Data
          Project overview
          Project Gallery
      */}

      <Mechanism></Mechanism>
      <Verification></Verification>

      {/*
      Leading Project developers
      News
      Partners and Collab
      Featured in
      CTA
      Footer
       */}
    </>
  );
}
